import React, { Component } from 'react'
import WritingTestPanel from "./writingTestPanel"

export default class WritingModuleMain extends Component {
    render() {
        return (
            <div>
                {<WritingTestPanel/>}
            </div>
        )
    }
}
