import React from "react";
import styles from "./style.module.css";
import StatisticsItem from "../util/UI/StatisticsItem";
import { useTranslation } from 'react-i18next';

const Statistics = ({
  cpm,
  wpm,
  lastScore,
  duration,
  accuracy,
  errorIndex,
}) => {
  const { t, i18n } = useTranslation();
  return (
    
      <div className={styles.main}>
        <StatisticsItem name={t("TypingTest.WPM")} value={wpm} />
        <StatisticsItem name={t("TypingTest.CPM")} value={cpm} />
        <StatisticsItem name={t("TypingTest.Errors")} value={errorIndex} />
        <StatisticsItem name={t("TypingTest.Accuracy")+"(%)"} value={accuracy} />
        <StatisticsItem name={t("TypingTest.Timer")} value={duration > 0 ? duration : 0} />
      </div>
    
  );
};

export default Statistics;
