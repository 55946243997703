import React from 'react'

const SelectDropDown = ({name,label,options,error, ...rest}) => {
    return ( 
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <select name={name} id={name}  {...rest} className="custom-select" >
                    <option  value="" />
                    {options.map(option=>(
                        <option key={option._id} value={option._id}>
                            {option.title}
                        </option>
                    ))}
                </select>

                {error && <div className="alert alert-danger">{error}</div>}


        </div>
     );
}
 
export default SelectDropDown;