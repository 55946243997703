import React, { useState } from "react";
import Input from "../common/input";
import "../../designStyles/AssessmentEntryForm.css";

const MultipleInputForm = ({ receiveDataCallback }) => {
  const [inputList_v2, setInputList_v2] = useState([
    {
      question: "",
      qsId: "",
      options: [""],
      answer: "",
    },
  ]);

  const handleChange = (e, index, optionIndex) => {
    const { name, value, type } = e.target;
    const list = [...inputList_v2];

    if (type === "radio") {
      // list[index].answer = optionIndex;
      list[index].answer = list[index].options[optionIndex];
    } else if (name === "option") {
      list[index].options[optionIndex] = value;
    } else {
      list[index][name] = value;
    }

    setInputList_v2(list);
  };

  const handleAddOption = (index) => {
    const list = [...inputList_v2];

    if (list[index].options.length < 5) {
      list[index].options.push("");
      setInputList_v2(list);
    }
  };

  const handleRemoveOption = (index, optionIndex) => {
    const list = [...inputList_v2];
    list[index].options.splice(optionIndex, 1);
    setInputList_v2(list);
  };

  const handleAddInput = () => {
    setInputList_v2([
      ...inputList_v2,
      { question: "", qsId: "", options: [""], answer: "" },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputList_v2];
    list.splice(index, 1);
    setInputList_v2(list);
  };

  const handleSetQuestions = () => {
    console.log("Submitted");
    receiveDataCallback(inputList_v2);
  };

  return (
    <React.Fragment>
      <div className="set-question">
        <div className="row align-items-center">
          <div className="col-sm-8">
            <div className="content-left">
              <h3 className="question-title">
                Please set the questions first and then Save it.
              </h3>
            </div>
          </div>
          <div className="col-sm-4">
            {
              <input
                className="mdk-set-btn"
                type="button"
                value="Set Questions"
                onClick={handleSetQuestions}
              />
            }
          </div>
        </div>
      </div>
      <div className="form-widget">
        {inputList_v2.map((item, i) => {
          return (
            <div className="form-repeat" key={i}>
              <div className="form-white">
                <div className="row">
                  <div className="col-md-6">
                    <Input
                      name="question"
                      value={item.question}
                      label="question"
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <div className="col-md-6">
                    <Input
                      name="qsId"
                      value={item.qsId}
                      label="qsId"
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <div className="col-md-12">
                    {item.options.length < 5 && (
                      <button
                        className="btn btn-primary"
                        onClick={() => handleAddOption(i)}
                      >
                        Add Option
                      </button>
                    )}{" "}
                    <div className="row">
                      <div className="options">
                        {item.options.map((option, optionIndex) => (
                          <div className="row" key={optionIndex}>
                            <div className="col-sm-8">
                              <div className="row">
                                <div className="col-sm-2">
                                  <label>{`Option ${optionIndex + 1}`}</label>
                                </div>
                                <div className="col-sm-6">
                                  <input
                                    type="text"
                                    name="option"
                                    // label={`Option ${optionIndex + 1}`}
                                    value={option}
                                    onChange={(e) =>
                                      handleChange(e, i, optionIndex)
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row" >
                                <div className="col-sm-2">
                                  <input
                                    type="radio"
                                    name={`correctAnswer-${i}`}
                                    checked={
                                      item.answer !== "" &&
                                      item.answer === option
                                    }
                                    onChange={(e) =>
                                      handleChange(e, i, optionIndex)
                                    }
                                  />
                                </div>
                                <div className="col-sm-2">
                                  <button
                                    className="btn btn-outline-danger btn-sm"
                                    onClick={() =>
                                      handleRemoveOption(i, optionIndex)
                                    }
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-sm-6"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Input
                      type="text"
                      name="answer"
                      label="Enter Answer"
                      value={item.answer}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <div className="col-md-12">
                    {inputList_v2.length !== 1 && (
                      <input
                        className="mdk-remove-btn"
                        type="button"
                        value="Remove Question"
                        onClick={() => handleRemoveInput(i)}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="col-md-12">
          <input
            className="mdk-add-btn"
            type="button"
            value="Add Question"
            onClick={handleAddInput}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MultipleInputForm;
