import React, { Component } from "react";
import Countdown from "react-countdown";
import Joi from "joi";
import { showSuccessToast, showErrorToast } from "../utils/toastMessage"; // Import the toast utility

import { submitEssayWritingScore } from "../../services/scoreService/scoreEWService";
import { getCurrentUser } from "../../services/authService";
import { withTranslation } from 'react-i18next';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "../../designStyles/WritingEssayTest/writingTest.css";

class WritingTestPanel extends Component {
  state = {
    userInfo: { userId: "" },
    value: "",
    timerStart: false,
    questionSelected: "",
    isLoading: false,
    countdownTimer: null,
    errors: "",
    startTime: null,
    Attempted: false,
    timeLeft: null,
    selectedLanguage:null
  };

  schema = Joi.object({
    Text: Joi.string().min(50).max(2500).label("Content").required(),
  }).messages({
    "string.base": "Please provide a valid input",
  });

  handleAlert = () => {
    const { t } = this.props;
    confirmAlert({
      title: t("articleWriting.Confirm to Start"),
      message: t("articleWriting.Are you sure to start the assessment."),
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          className: "btn btn-primary",
          label: t("articleWriting.Start Assessment"),
          onClick: () => {
              this.setState({ countdownTimer: Date.now() + 1200000 }); // Initialize countdown timer
            this.startAssessment();
            this.setState({ startTime: Date.now() });
          },
        },
        {
          className: "btn btn-outline",
          label: t("articleWriting.Cancel"),
          onClick: () => {
            window.removeEventListener("beforeunload", this._confirm);
            window.removeEventListener("popstate", this._backConfirm);
            window.location = "/allAssessmentTypes";
          },
        },
      ],
    });
  };
  startAssessment = () => {
    this.setState({ timerStart: true });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ value });
    const { error } = this.schema.validate({ Text: value });
    this.setState({
      errors: error
        ? { ...this.state.errors, Text: error.details[0].message }
        : { ...this.state.errors, Text: "" },
    });
  };

  validateForm = () => {
    const { error } = this.schema.validate({ value: this.state.value });
    this.setState({ errors: error });
  };
  handleQuestionChange = (event) => {
    this.setState({ questionSelected: event.target.value });
  };

  handleSubmit = async (countDownOver) => {
    const { value, questionSelected } = this.state;
    const { t } = this.props;
    const { error } = this.schema.validate({ Text: value });
    if ((countDownOver || !error) && questionSelected) {
      this.setState({ isLoading: true });
      this.setState({ timerStart: false });

      const userId = this.state.userInfo.userId;
      const textAnswer = this.state.value || "random";
      try {
        const response = await submitEssayWritingScore(
          userId,
          textAnswer,
          questionSelected,
          this.state.selectedLanguage
        );
        this.setState({ isLoading: false });
        showSuccessToast(t(`common.${response.data.message}`));
        window.removeEventListener("beforeunload", this._confirm);
        window.removeEventListener("popstate", this._backConfirm);
        setTimeout(() => {
          window.location = "/allAssessmentTypes";
        }, 3000);
      } catch (ex) {
        if (
          ex.response &&
          ex.response.status >= 400 &&
          ex.response.status <= 500
        ) {
          showErrorToast(t(`common.${ex.response.data.message}`));
        }
      }
    } else {
      if (!questionSelected) {
        showErrorToast(t("articleWriting.Please select a question."));
      } else {
        showErrorToast(t("articleWriting.Please fill out the form."));
      }
    }
    localStorage.removeItem("attemptAndTimeWriting");
  };

  timercountdown = () => {
    return (
      <React.Fragment>
        <div>
          <Countdown
            date={this.state.countdownTimer}
            onComplete={() => this.handleSubmit(true)}
          />
        </div>
      </React.Fragment>
    );
  };

  saveDataToLocalStorage() {
    const attemptAndTimeWriting = {
      time: Date.now(),
      count:
        JSON.parse(localStorage.getItem("attemptAndTimeWriting"))?.count + 1 ||
        1,
      countdownTimer: Math.abs(this.state.countdownTimer),
      value: this.state.value,
      questionSelected: this.state.questionSelected,
    };
    localStorage.setItem(
      "attemptAndTimeWriting",
      JSON.stringify(attemptAndTimeWriting)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.removeEventListener("popstate", this._backConfirm);
    this.saveDataToLocalStorage();
  }

  _backConfirm = (e) => {
    const { t } = this.props
    this.saveDataToLocalStorage();
    if (!this.state.submitDataFlag) {
      e.preventDefault();
      let event = window.confirm(t("common.Changes that you may not be saved."));
      if (event) {
        window.history.back();
      } else {
        window.history.pushState(null, "", window.location.href);
      }
    }
  };

  _confirm = (e) => {
    const { t } = this.props
    this.saveDataToLocalStorage();
    if (!this.state.submitDataFlag) {
      var confirmationMessage = t("common.Do you want to leave this page.");
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.addEventListener("popstate", this._backConfirm);
    window.history.pushState(null, "", window.location.href);
    try {
      const user = getCurrentUser();
      this.setState({ userInfo: { userId: user._id } });
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 500
      ) {
        showErrorToast(ex.response.data.message);
      }
    }
    const localData = JSON.parse(localStorage.getItem("attemptAndTimeWriting"));
    const timestamp1 = localData?.time;
    const timestamp2 = Date.now();
    const difference = Math.abs(timestamp1 - timestamp2);
    const threeMinutesInMilliseconds = 3 * 60 * 1000;
    let reAttempt = false;
    if (difference <= threeMinutesInMilliseconds) {
      reAttempt = true;
    }
    if (localData && reAttempt) {
      const timeLeft = Math.abs(localData.countdownTimer - localData.time);
      this.setState({ Attempted: true });
      this.setState({ timeLeft: timeLeft });
      this.setState({ value: localData?.value });
      this.setState({ questionSelected: localData?.questionSelected });
        this.setState({
          countdownTimer: Date.now() + timeLeft,
        });
      this.startAssessment();
      this.setState({ startTime: Date.now() });
    } else {
      this.handleAlert();
    }

    const { i18n } = this.props;
    const language = localStorage.getItem("selectedLanguage")
    this.setState({selectedLanguage:language})
    i18n.changeLanguage(language);
  }

  render() {
    const { questionSelected, value, errors } = this.state;
    const { user, t } = this.props;
    return (
      <div>
        <div className="form-area">
          <div className="container">
            <div className="common-top">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-white article-heading">
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          className="form-check-input"
                          onChange={this.handleQuestionChange}
                          value={`Option 1: ) ${t("articleWriting.Write a letter to your local newspaper in which you state your opinion on the effects computers have on people. Persuade the readers to agree with you.")}`}
                          checked={
                            questionSelected ===
                            `Option 1: ) ${t("articleWriting.Write a letter to your local newspaper in which you state your opinion on the effects computers have on people. Persuade the readers to agree with you.")}`
                          }
                        />
                       Option 1: {t("articleWriting.Write a letter to your local newspaper in which you state your opinion on the effects computers have on people. Persuade the readers to agree with you.")}
                      </label>
                    </div>
                    <div className="form-check">
                      <label className="form-check-label">
                        <input
                          type="radio"
                          className="form-check-input"
                          onChange={this.handleQuestionChange}
                          value={`Option 2: ${t("articleWriting.Write about patience. Being patient means that you are understanding and tolerant. A patient person experience difficulties without complaining.")}`}
                          checked={
                            questionSelected ===
                            `Option 2: ${t("articleWriting.Write about patience. Being patient means that you are understanding and tolerant. A patient person experience difficulties without complaining.")}`
                          }
                        />
                        {`Option 2: ${t("articleWriting.Write about patience. Being patient means that you are understanding and tolerant. A patient person experience difficulties without complaining.")}`}
                      </label>
                    </div>
                  </div>
                  <div className="form-white">
                    <div className="row">
                      <div className="col-md-9">
                        <div className="form-group">
                          <textarea
                            name="value"
                            type="text"
                            value={value}
                            className="form-control"
                            placeholder={t("articleWriting.Enter your text here (max 2500 characters)")}
                            onChange={this.handleChange}
                            defaultValue={""}
                            disabled={!questionSelected?.length}
                            spellCheck={false}
                            onPaste={(e) => e.preventDefault()}
                          />
                        </div>
                        {errors.Text && (
                          <div className="text-danger">{t("articleWriting.'Content' length must be at least 50 characters long")}</div>
                        )}
                      </div>
                      <div className="col-md-3">
                        <div className="timer-widget">
                          <div className="mdk-de-timer">
                            {this.state.timerStart && this.timercountdown()}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <button
                            className="button button--wayra"
                            onClick={this.handleSubmit}
                            disabled={
                              this.state.isLoading ||
                              !!this.state.errors?.Text?.length ||
                              !this.state.value?.length
                            }
                          >
                            {this.state.isLoading ? t("articleWriting.Submitting...") : t("articleWriting.Submit")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(WritingTestPanel);
