import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import { withTranslation } from 'react-i18next';
import { forgotPassword } from "../services/authService";
import "./../designStyles/LoginForm.css";
import "./../designStyles/AllForms.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { showErrorToast, showSuccessToast } from "./utils/toastMessage";

class ForgetPass extends Form {
  state = {
    data: { email: "" },
    errors: {},
    isLoading: false,
    isDisabled: false,
    languageLoaded: false
  };

  schema = {
    email: Joi.string().email().required().label("Email ID"),
  };

  doSubmit = async () => {
    const { i18n,t } = this.props;
    try {
      //call the server
      const { data } = this.state;
      let response = await forgotPassword(data.email,i18n.language);
      showSuccessToast(t(`ForgotPassword.${response.data.message}`));
      setTimeout(() => {
        window.location = "/login";
        // window.location.reload(); // Refresh the page
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        showErrorToast(t(`ForgotPassword.${ex.response.data.message}`));
      }
    }
  };

  changeLanguage = async (lng) => {
    const { i18n } = this.props;
    await i18n.changeLanguage(lng);
    this.setState({languageLoaded:true})
  };

  componentDidMount() {
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    this.changeLanguage(selectedLanguage);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/* <ToastContainer /> */}
        <section id="login-page" className="login-page">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="login-left-main">
                  <img
                    src="images/login-bg-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="login-overlay">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="heading-white">
                          {t("ForgotPassword.Welcome back")}
                        </h2>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          {t(
                            "ForgotPassword.Show your true potential with Semantify. The easy to use and intelligent talent assessment, evaluation and management platform, offering an engaging and seamless test experience. Register and start your tests now."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="right-login">
                  <div className="body-widget">
                    <h2 className="heading-black">
                      {t("ForgotPassword.Forgot Password")}
                    </h2>
                    <form onSubmit={this.handleSubmit}>
                      {this.renderInput("email", t("ForgotPassword.Email"))}
                      {this.renderButton(t("ForgotPassword.Submit"))}
                    </form>
                    <br></br>
                    <a href="/updatePass" style={{ float: "right" }}>
                      {" "}
                      {t("ForgotPassword.Update Password")}
                    </a>{" "}
                    <p>
                      {t("ForgotPassword.Don't have an account?")}
                      <a href="/registration">
                        {" "}
                        {t("ForgotPassword.Sign Up")}{" "}
                      </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-main">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ForgetPass);
