import React, { Component } from "react";
import Joi from "joi-browser";
import { compareTwoStrings } from "string-similarity";
import "../../designStyles/DEPanelMain/DePanel_v1.css";
import Countdown from "react-countdown";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import ReactHowler from "react-howler";
import { q1_DataEntry } from "../../bank/DataEntryQ_v1";
import Terri_AEng_s1 from "../../bank/Terri_AEng_s1.mp3";
import { submitDataEntryScore } from "../../services/scoreService/scoreDEService";
import { getCurrentUser } from "../../services/authService";
import { showSuccessToast,showErrorToast } from "../utils/toastMessage";


export default class DataEntryPanel_q2 extends Component {
  state = {
    userInfo: { userId: "" },
    assessmentName: "",
    score_1: 0,
    score_2: 0,
    scores: { score_1: 0, score_2: 0 },
    replayCount: 0,
    timerStart: false,
    playAudioClip: false,
    inputFields: {
      name: "",
      address: "",
      phoneNumber: "",
      cpin: "",
      shippingDetails: "",
    },
    audioClip: "",
    replay: false,
    countdownTimer: null,
    errors: {},
    startTime: null,
    Attempted: false,
    timeLeft: null,
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  validate = () => {
    const schema = {
      name: Joi.string().max(20).required().label("Name"),
      address: Joi.string().max(50).required().label("Address"),
      phoneNumber: Joi.string().max(15).required().label("Phone Number"),
      shippingDetails: Joi.string()
        .max(50)
        .required()
        .label("Shipping Details"),
      cpin: Joi.string().max(20).required().label("CPIN"),
    };
    const { inputFields } = this.state;
    const { error } = Joi.validate(inputFields, schema, { abortEarly: false });
    if (!error) return null;

    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }

    return errors;
  };

  playClip = (src) => {
    this.setState((prevState) => {
      return {
        playAudioClip: true,
        replayCount: prevState.replayCount + 1,
      };
    });
  };

  stopClip = () => {
    this.setState({
      playAudioClip: false,
      replay: true,
    });
    // this.setState((prevState) => {
    //   return {
    //     playAudioClip: false,
    //     replay: !prevState.replay,
    //   };
    // });
  };

  handleAlert = () => {
    confirmAlert({
      title: "Confirm to Start",
      message: "Are you sure to start the assesment.",
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          className: "mdk-confirm-yes",
          label: "Yes",
          onClick: () => {
            this.setState({ countdownTimer: Date.now() + 180000 }); // Initialize countdown timer
            this.startAssessment();
            setTimeout(() => {
              this.focusInputField();
            }, 200);
          },
        },
        {
          className: "mdk-confirm-no",
          label: "No",
          onClick: () => {
            window.removeEventListener("beforeunload", this._confirm);
            window.removeEventListener("popstate", this._backConfirm);
            window.location = "/dataEntryAssessment";
          },
        },
      ],
    });
  };

  startAssessment = () => {
    this.setState((prevState) => {
      return {
        timerStart: true,
      };
    });
    this.playClip();
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      inputFields: {
        ...prevState.inputFields,
        [name]: value,
      },
    }));
  };

  checkInputAnswer_v2 = async (e, countDown) => {
    if (!countDown) {
      e.preventDefault();
      const errors = this.validate();
      this.setState({ errors: errors || {} });

      if (errors) return;
    }

    const { inputFields } = this.state;
    let total = 0;
    for (const key of Object.keys(q1_DataEntry)) {
      let similarity = 0;
      similarity = await compareTwoStrings(inputFields[key], q1_DataEntry[key]);
      total = total + similarity;
    }
    this.setState({ score_1: total }, () => this.handleSubmit_v2());
  };

  handleSubmit_v2 = async () => {
    try {
      this.setState({ timerStart: false });
      const { userId } = this.state.userInfo;
      const { assessmentName, score_1, replayCount } = this.state;
      const response = await submitDataEntryScore(
        userId,
        assessmentName,
        score_1,
        replayCount
      );
      showSuccessToast(response.data.message);
      setTimeout(() => {
        this.props.history.push("/dataEntryAssessment");
        localStorage.removeItem("attemptAndTimeListeningQ2");
        // window.location = "/allAssessmentTypes";
        // window.location.reload(); // Refresh the page
      }, 3000);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        showErrorToast(ex.response.data.message);
      }
    }
  };

  timercountdown = () => {
    //timerStartSignal and TimerTimeLimit
    if (this.state.timerStart) {
      return (
        <React.Fragment>
          <div>
            <Countdown
              date={this.state.countdownTimer}
              onComplete={(e) => this.checkInputAnswer_v2(e, true)}
            />
          </div>
        </React.Fragment>
      );
    }
  };

  saveDataToLocalStorage() {
    const attemptAndTimeListeningQ2 = {
      time: Date.now(),
      count:
        JSON.parse(localStorage.getItem("attemptAndTimeListeningQ2"))?.count +
          1 || 1,
      countdownTimer: Math.abs(this.state.countdownTimer),
      selectedAnswers: this.state.selectedAnswers,
      inputFields: this.state.inputFields,
      replayCount: this.state.replayCount,
    };
    localStorage.setItem(
      "attemptAndTimeListeningQ2",
      JSON.stringify(attemptAndTimeListeningQ2)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this._confirm);
    window.removeEventListener("popstate", this._backConfirm);
    this.saveDataToLocalStorage();
  }

  _backConfirm = (e) => {
    const { t } = this.props
    this.saveDataToLocalStorage();
    if (!this.state.submitDataFlag) {
      e.preventDefault();
      let event = window.confirm(t("common.Changes that you may not be saved."));
      if (event) {
        window.history.back();
      } else {
        window.history.pushState(null, "", window.location.href);
      }
    }
  };

  _confirm = (e) => {
    const { t } = this.props
    this.saveDataToLocalStorage();
    if (!this.state.submitDataFlag) {
      var confirmationMessage = t("common.Do you want to leave this page.");
      e.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  };
  
  async componentDidMount() {
    window.addEventListener("beforeunload", this._confirm);
    window.addEventListener("popstate", this._backConfirm);
    window.history.pushState(null, "", window.location.href);

    try {
      const user = await getCurrentUser();
      this.setState({ userInfo: { userId: user._id } });
      this.setState({ assessmentName: "American English Question-1" });
    } catch (ex) {}

    const localData = JSON.parse(
      localStorage.getItem("attemptAndTimeListeningQ2")
    );
    const timestamp1 = localData?.time;
    const timestamp2 = Date.now();
    const difference = Math.abs(timestamp1 - timestamp2);
    const threeMinutesInMilliseconds = 3 * 60 * 1000;
    let reAttempt = false;
    if (difference <= threeMinutesInMilliseconds) {
      reAttempt = true;
    }
    if (localData && reAttempt) {
      const timeLeft = Math.abs(localData.countdownTimer - localData.time);
      this.setState({ Attempted: true });
      this.setState({ timeLeft: timeLeft });
      this.setState({ inputFields: localData?.inputFields });
      this.setState({ replayCount: localData?.replayCount });

      this.setState({
        countdownTimer: Date.now() + this.state.timeLeft,
      });
      this.startAssessment();
      setTimeout(() => {
        this.focusInputField();
      }, 200);
    } else {
      this.handleAlert();
    }
  }

  focusInputField = () => {
    this.inputRef.current.focus();
  };

  render() {
    const { inputFields, playAudioClip, replay, errors } = this.state;

    return (
      <div className="form-area-new">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="mdk-assetment-bg text-white">
                <div className="mdk--testHeader">
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  Please submit once you have completed answering the question.
                  Do not change pages untill submitted successfully.
                  <br></br>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  Page will refresh automatically once submitted.
                  <br></br>
                  <i className="fa fa-info-circle" aria-hidden="true"></i>
                  Play the Audio and fill in the form accordingly. Replay to
                  listen again. Remember number of replays/attempts are
                  recorded.
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-4">
              {/* <div className="form-white"> */}
              <div className="mdk-de-timer">
                {this.state.timerStart && this.timercountdown()}{" "}
              </div>
              {/* </div> */}
            </div>
            <div className="col-sm-4">
              <ReactHowler
                src={Terri_AEng_s1}
                playing={playAudioClip}
                onEnd={this.stopClip}
              />{" "}
              <button
                className="button button--wayra"
                onClick={this.playClip}
                disabled={this.state.playAudioClip}
              >
                {replay ? "Replay Audio" : "Playing..."}
              </button>
            </div>
          </div>
          <form>
            <div className="common-top">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-white">
                    <div className="row">
                      {Object.keys(inputFields).map((field, index, array) => (
                        <div
                          className={`col-md-${
                            field === array[array.length - 1] ? "12" : "6"
                          }`}
                          key={field}
                        >
                          <div className="form-group">
                            <label>
                              {field.charAt(0).toUpperCase() + field.slice(1)}:
                            </label>
                            {field === array[array.length - 1] ? (
                              <textarea
                                className="form-control"
                                placeholder={`Enter ${field}`}
                                value={inputFields[field]}
                                onChange={this.handleInputChange}
                                name={field}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                placeholder={`Enter ${field}`}
                                value={inputFields[field]}
                                onChange={this.handleInputChange}
                                name={field}
                                ref={index === 0 ? this.inputRef : null}
                              />
                            )}
                            {errors[field] && (
                              <div className="text-danger">{errors[field]}</div>
                            )}
                          </div>
                        </div>
                      ))}
                      <div className="col-md-12">
                        <div className="form-group">
                          <button
                            className="button button--wayra"
                            type="submit"
                            onClick={this.checkInputAnswer_v2}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

