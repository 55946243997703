export const location_options= [
  { id:1,value: "USA", label: "USA" },
  { id:2,value: "Jamaica", label: "Jamaica" },
  { id:3,value: "El Salvador", label: "El Salvador" },
  { id:4,value: "Philippiness", label: "Philippines" },
  { id:5,value: "Mexico", label: "Mexico"},

  { id:6,value: "India-Kolkata", label: "India-Kolkata" },
  { id:7,value: "India-Mohali", label: "India-Mohali" },
  { id:8,value: "India-Mumbai", label: "India-Mumbai"},
  { id:9,value: "India-Chennai", label: "India-Chennai"},
  { id:10,value: "India-Kochi", label: "India-Kochi"},
  { id:11,value: "India-Noida", label: "India-Noida"},
  { id:12,value: "India-Howrah", label: "India-Howrah"},
  { id:13,value: "India-Kalyani", label: "India-Kalyani"},
  { id:14,value: "India-Durgapur", label: "India-Durgapur"},
  { id:15,value: "India-Other", label: "India-Other" },
  { id:16,value: "Colombia", label: "Colombia" },
  { id:17,value: "Morocco–Casablanca", label: "Morocco–Casablanca"},
  { id:18,value: "Albania", label: "Albania"},
  { id:19,value: "France-Paris", label: "France-Paris"},
  { id:20,value: "Kosovo", label: "Kosovo"},
  { id:21,value: "UK-London", label: "UK-London"},
  { id:22,value: "Mexico", label: "Mexico"},
  { id:23,value: "Belize", label: "Belize"},
  { id:24,value: "Canada-Montreal", label: "Canada-Montreal"},
  { id:25,value: "USA-Atlanta", label: "USA-Atlanta"},
  { id:26,value: "USA-Cambridge W@H", label: "USA-Cambridge W@H"},
  { id:27,value: "USA-Draper", label: "USA-Draper"},
  { id:28,value: "USA-Higbee", label: "USA-Higbee"},
  { id:29,value: "USA-Spindale", label: "USA-Spindale"},
  { id:30,value: "USA-Utah", label: "USA-Utah"},
  { id:31,value: "USA-Florida", label: "USA-Florida"},
  { id:32,value: "India-Bengaluru", label: "India-Bengaluru"},
  { id:33,value: "India-Chandigarh", label: "India-Chandigarh"},
  { id:34,value: "Indonesia", label: "Indonesia"},
  { id:35,value: "Philippines-Cebu", label: "Philippines-Cebu"},
  { id:36,value: "Philippines-Legazpi", label: "Philippines-Legazpi"},
  { id:37,value: "Philippines-Manila", label: "Philippines-Manila"},
  { id:38,value: "Philippines-Ortigas", label: "Philippines-Ortigas"},
  { id:39,value: "Philippines-Silang", label: "Philippines-Silang"},
  { id:40,value: "Thailand", label: "Thailand"}
];

export const location_options_In_French = [
  { id: 1, value: "USA", label: "États-Unis" },
  { id: 2, value: "Jamaica", label: "Jamaïque" },
  { id: 3, value: "El Salvador", label: "Le Salvador" },
  { id: 4, value: "Philippiness", label: "Philippines" },
  { id: 5, value: "Mexico", label: "Mexique" },
  { id: 6, value: "India-Kolkata", label: "Inde-Kolkata" },
  { id: 7, value: "India-Mohali", label: "Inde-Mohali" },
  { id: 8, value: "India-Mumbai", label: "Inde-Mumbai" },
  { id: 9, value: "India-Chennai", label: "Inde-Chennai" },
  { id: 10, value: "India-Kochi", label: "Inde-Kochi" },
  { id: 11, value: "India-Noida", label: "Inde-Noida" },
  { id: 12, value: "India-Howrah", label: "Inde-Howrah" },
  { id: 13, value: "India-Kalyani", label: "Inde-Kalyani" },
  { id: 14, value: "India-Durgapur", label: "Inde-Durgapur" },
  { id: 15, value: "India-Other", label: "Inde-Autre" },
  { id: 16, value: "Colombia", label: "Colombie" },
  { id: 17, value: "Morocco–Casablanca", label: "Maroc–Casablanca" },
  { id: 18, value: "Albania", label: "Albanie" },
  { id: 19, value: "France-Paris", label: "France-Paris" },
  { id: 20, value: "Kosovo", label: "Kosovo" },
  { id: 21, value: "UK-London", label: "Royaume-Uni-Londres" },
  { id: 22, value: "Mexico", label: "Mexique" },
  { id: 23, value: "Belize", label: "Belize" },
  { id: 24, value: "Canada-Montreal", label: "Canada-Montréal" },
  { id: 25, value: "USA-Atlanta", label: "États-Unis-Atlanta" },
  { id: 26, value: "USA-Cambridge W@H", label: "États-Unis-Cambridge W@H" },
  { id: 27, value: "USA-Draper", label: "États-Unis-Draper" },
  { id: 28, value: "USA-Higbee", label: "États-Unis-Higbee" },
  { id: 29, value: "USA-Spindale", label: "États-Unis-Spindale" },
  { id: 30, value: "USA-Utah", label: "États-Unis-Utah" },
  { id: 31, value: "USA-Florida", label: "États-Unis-Floride" },
  { id: 32, value: "India-Bengaluru", label: "Inde-Bangalore" },
  { id: 33, value: "India-Chandigarh", label: "Inde-Chandigarh" },
  { id: 34, value: "Indonesia", label: "Indonésie" },
  { id: 35, value: "Philippines-Cebu", label: "Philippines-Cebu" },
  { id: 36, value: "Philippines-Legazpi", label: "Philippines-Legazpi" },
  { id: 37, value: "Philippines-Manila", label: "Philippines-Manille" },
  { id: 38, value: "Philippines-Ortigas", label: "Philippines-Ortigas" },
  { id: 39, value: "Philippines-Silang", label: "Philippines-Silang" },
  { id: 40, value: "Thailand", label: "Thaïlande" }
];

export const location_filter= [
  { id:1,value:"All",label:"All"},
  { id:2,value: "USA", label: "USA" },
  { id:3,value: "Jamaica", label: "Jamaica" },
  { id:4,value: "El Salvador", label: "El Salvador" },
  { id:5,value: "Philippines", label: "Philippines" },
  { id:6,value: "Mexico", label: "Mexico"},

  { id:7,value: "India-Kolkata", label: "India-Kolkata" },
  { id:8,value: "India-Mohali", label: "India-Mohali" },
  { id:9,value: "India-Mumbai", label: "India-Mumbai"},
  { id:10,value: "India-Chennai", label: "India-Chennai"},
  { id:11,value: "India-Kochi", label: "India-Kochi"},
  { id:12,value: "India-Noida", label: "India-Noida"},
  { id:13,value: "India-Howrah", label: "India-Howrah"},
  { id:14,value: "India-Kalyani", label: "India-Kalyani"},
  { id:15,value: "India-Durgapur", label: "India-Durgapur"},
  { id:16,value: "India-Other", label: "India-Other" },
  { id:17,value: "Colombia", label: "Colombia" },
  { id:18,value: "Morocco–Casablanca", label: "Morocco–Casablanca" },
  { id:19,value: "Albania", label: "Albania"},
  { id:20,value: "France-Paris", label: "France-Paris"},
  { id:21,value: "Kosovo", label: "Kosovo"},
  { id:22,value: "UK-London", label: "UK-London"},
  { id:23,value: "Mexico", label: "Mexico"},
  { id:24,value: "Belize", label: "Belize"},
  { id:25,value: "Canada-Montreal", label: "Canada-Montreal"},
  { id:26,value: "USA-Atlanta", label: "USA-Atlanta"},
  { id:27,value: "USA-Cambridge W@H", label: "USA-Cambridge W@H"},
  { id:28,value: "USA-Draper", label: "USA-Draper"},
  { id:29,value: "USA-Higbee", label: "USA-Higbee"},
  { id:30,value: "USA-Spindale", label: "USA-Spindale"},
  { id:31,value: "USA-Utah", label: "USA-Utah"},
  { id:32,value: "USA-Florida", label: "USA-Florida"},
  { id:33,value: "India-Bengaluru", label: "India-Bengaluru"},
  { id:34,value: "India-Chandigarh", label: "India-Chandigarh"},
  { id:35,value: "Indonesia", label: "Indonesia"},
  { id:36,value: "Philippines-Cebu", label: "Philippines-Cebu"},
  { id:37,value: "Philippines-Legazpi", label: "Philippines-Legazpi"},
  { id:38,value: "Philippines-Manila", label: "Philippines-Manila"},
  { id:39,value: "Philippines-Ortigas", label: "Philippines-Ortigas"},
  { id:40,value: "Philippines-Silang", label: "Philippines-Silang"},
  { id:41,value: "Thailand", label: "Thailand"}
];

export const location_filter_In_French = [
  { id: 1, value: "All", label: "Tout" },
  { id: 2, value: "USA", label: "États-Unis" },
  { id: 3, value: "Jamaica", label: "Jamaïque" },
  { id: 4, value: "El Salvador", label: "Le Salvador" },
  { id: 5, value: "Philippines", label: "Philippines" },
  { id: 6, value: "Mexico", label: "Mexique" },
  { id: 7, value: "India-Kolkata", label: "Inde-Kolkata" },
  { id: 8, value: "India-Mohali", label: "Inde-Mohali" },
  { id: 9, value: "India-Mumbai", label: "Inde-Mumbai" },
  { id: 10, value: "India-Chennai", label: "Inde-Chennai" },
  { id: 11, value: "India-Kochi", label: "Inde-Kochi" },
  { id: 12, value: "India-Noida", label: "Inde-Noida" },
  { id: 13, value: "India-Howrah", label: "Inde-Howrah" },
  { id: 14, value: "India-Kalyani", label: "Inde-Kalyani" },
  { id: 15, value: "India-Durgapur", label: "Inde-Durgapur" },
  { id: 16, value: "India-Other", label: "Inde-Autre" },
  { id: 17, value: "Colombia", label: "Colombie" },
  { id: 18, value: "Morocco–Casablanca", label: "Maroc–Casablanca" },
  { id: 19, value: "Albania", label: "Albanie" },
  { id: 20, value: "France-Paris", label: "France-Paris" },
  { id: 21, value: "Kosovo", label: "Kosovo" },
  { id: 22, value: "UK-London", label: "Royaume-Uni-Londres" },
  { id: 23, value: "Mexico", label: "Mexique" },
  { id: 24, value: "Belize", label: "Belize" },
  { id: 25, value: "Canada-Montreal", label: "Canada-Montréal" },
  { id: 26, value: "USA-Atlanta", label: "États-Unis-Atlanta" },
  { id: 27, value: "USA-Cambridge W@H", label: "États-Unis-Cambridge W@H" },
  { id: 28, value: "USA-Draper", label: "États-Unis-Draper" },
  { id: 29, value: "USA-Higbee", label: "États-Unis-Higbee" },
  { id: 30, value: "USA-Spindale", label: "États-Unis-Spindale" },
  { id: 31, value: "USA-Utah", label: "États-Unis-Utah" },
  { id: 32, value: "USA-Florida", label: "États-Unis-Floride" },
  { id: 33, value: "India-Bengaluru", label: "Inde-Bangalore" },
  { id: 34, value: "India-Chandigarh", label: "Inde-Chandigarh" },
  { id: 35, value: "Indonesia", label: "Indonésie" },
  { id: 36, value: "Philippines-Cebu", label: "Philippines-Cebu" },
  { id: 37, value: "Philippines-Legazpi", label: "Philippines-Legazpi" },
  { id: 38, value: "Philippines-Manila", label: "Philippines-Manille" },
  { id: 39, value: "Philippines-Ortigas", label: "Philippines-Ortigas" },
  { id: 40, value: "Philippines-Silang", label: "Philippines-Silang" },
  { id: 41, value: "Thailand", label: "Thaïlande" }
];

export const Language = [
  { id:1,value: "en", label: "English" },
  { id:2,value: "fr", label: "Français" }
]
export default {location_options,location_filter};
