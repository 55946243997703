import React from 'react';

const Input = ({type,name , label , value , onChange , error,inputRef, disabled}) => {
    return ( 
        <div className= "form-group">
            <label htmlFor={name}>{label}</label>
             <input 
             value={value}
             onChange={onChange}
             name={name}
             id={name} 
             type = {type} 
             className="form-control"
             ref = {inputRef} 
             disabled = {disabled}
             />
    { error && <div className="alert alert-danger">{error}</div>}
                  </div>
     );
}
 
export default Input;