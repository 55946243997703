import http from "./httpService";

const apiEndpoint = "/allModules";

function moduleUrl(id) {
  return `${apiEndpoint}/${id}`;
}


export function getModules() {
  return http.get(apiEndpoint);
}


export function saveModule(module) {
  if (module._id) {
    const body = { ...module };
    delete body._id;
    return http.put(moduleUrl(module._id), body);
  }
  return http.post(apiEndpoint, module);
}


