import React from "react";
import Joi from "joi-browser";
// import Joi from "joi";

import Form from "./common/form";

import { getLoginDetails, login } from "./../services/authService";
import "./../designStyles/LoginForm.css";
import { showErrorToast } from "./utils/toastMessage";
import { withTranslation } from 'react-i18next';

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    userUuid: "",
    userDetails:"",
  };

  schema = {
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label("Email ID"),
    password: Joi.string().min(5).required().label("Password"),
  };

  doSubmit = async () => {
    const { t } = this.props;
    try {
      const { data } = this.state;
      await login(data.email, data.password);
      window.location = "/allAssessmentTypes";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        showErrorToast(t(`LogIn.${ex.response.data.message}`));
      }
    }
  };

 async getUsersDetail(userUuid){
    const userDetails = await getLoginDetails(userUuid);
    this.setState({ userDetails });
    this.setState({ data:{email:userDetails?.email} });
  }
 
  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const userUuid = params.get("userUuid");
    const language = params.get("language");
    this.setState({ userUuid });
    this.getUsersDetail(userUuid);

    let selectedLanguage = "";
    if (language) {
      selectedLanguage = language;
      localStorage.setItem("selectedLanguage", language);
    } else {
      selectedLanguage = localStorage.getItem("selectedLanguage") || "en";
    }
    this.changeLanguage(selectedLanguage);
  }

  changeLanguage(lng) {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/* <ToastContainer /> */}
        <section id="login-page" className="login-page">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="login-left-main">
                  <img
                    src="images/login-bg-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="login-overlay">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="heading-white">{t('LogIn.Welcome back')}</h2>
                      </div>
                      <div className="col-sm-12">
                        <p>
                         {t('LogIn.True Potential Message')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="right-login">
                  <div className="body-widget">
                    <h2 className="heading-black"><h1>{t('LogIn.Log In')}</h1></h2>
                    {/* <h2 className="heading-black">Log In</h2> */}
                    <form onSubmit={this.handleSubmit}>
                      {this.state.userUuid ? this.renderInput("email", t('LogIn.Email'),true) : this.renderInput("email", t('LogIn.Email'))}
                      {this.state.userUuid
                        ? this.renderPassword(
                            "password",
                            t('LogIn.Temporary Password*'),
                            "password"
                          )
                        : this.renderPassword(
                            "password",
                            t('LogIn.Password'),
                            "password"
                          )}
                      {/* {this.renderPassword("password", "Password", "password")} */}
                      {this.renderButton(t("LogIn.Sign In"))}
                    </form>
                    <a href="/reset" style={{ float: "right" }}>
                      {t('LogIn.Forgot Password')}
                    </a>{" "}
                    <br></br>
                    <a href="/updatePass" style={{ float: "right" }}>
                      {" "}
                      {t('LogIn.Update Password')}
                    </a>{" "}
                    <p>
                      {t("LogIn.Don't have an account?")}
                      <a href="/registration"> {t('LogIn.Sign Up')} </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-main">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default  withTranslation()(LoginForm);
