import axios from "axios";
// import { toast } from "react-toastify";
// import logger from "./logService"
//import auth from "./authService"
//axios.defaults.headers.common['x-auth-token'] = auth.getJwt(); 

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // logger.log(error)
    // console.log("Logging the error", error);
    // toast.error("An unexpected error occusrred.");
  }
  return Promise.reject(error);
});

function setJwt(jwt){

  axios.defaults.headers.common['x-auth-token'] = jwt; 

}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt
};
