import http from "./httpService";

const apiEndpoint = "/userassignmentprofile";

function userUrl(userId) {
    return `${apiEndpoint}/${userId}`;
  }
  
  export function getAllUserAssignmentProfile(stDate,enDate,uLoc) {
    return http.get(apiEndpoint,{params:{
      userLocation: uLoc,
      startDate: stDate,
      endDate: enDate
  }});
  }
  
  export function getOneUserAssignmentProfile(userId){
    return http.get(userUrl(userId));
  }
  
  export function deleteUserAssignmentProfile(userId){
      return http.delete(apiEndpoint + "/" + userId);
  }
  
  
  export function addUserAssignmentProfile(candidate_Id,recruiter_Id,profile){
    return http.post(apiEndpoint , {
      candidateId:candidate_Id,
      recruiterId:recruiter_Id,
      recruitmentStatus:profile.recruitmentStatus,
      assignedAssessments:profile.assignedAssessments,
      assignedMQAssessments:profile.assignedMQAssessments,
      assignedDEAssessments:profile.assignedDEAssessments,
      assignedTSAssessments:profile.assignedTSAssessments,
      assignedEWAssessments:profile.assignedEWAssessments,
      assignedSAAssessments:profile.assignedSAAssessments,
      isArchived:profile.isArchived,
      isAdmin:profile.isAdmin
    })
  }