 const q1_DataEntry ={

    name:"Isabella Johnson",
    address:"4945 Perine Street, New Mexico",
    phoneNumber:"7037291453",
    cpin:"99067554768",
    shippingDetails:"Will be needing 3 bottles of sanitizers,delivered by tomorrow evening.Please make sure the bottles are packed properly.",

} 
 const q1_DataEntry_French = {

    name:"Isabella Johnson",
    address:"4945 rue Perine, Nouveau-Mexique",
    phoneNumber:"7037291453",
    cpin:"99067554768",
    shippingDetails:"Vous aurez besoin de 3 bouteilles de désinfectant, livrées d'ici demain soir. Veuillez vous assurer que les bouteilles sont correctement emballées.",

} 
export {q1_DataEntry, q1_DataEntry_French};
//
// Hi this is Isabella Johnson
// Speaking from 4945 Perine Street, New Mexico 
// zip code is 80007
// phone num is 7037291453
// my cpin id is 99067554768
// Will be needing 3 bottles of sanitizers ,delivered by tomorrow evening.
// Please make sure the bottles are packed properly .


// Hi this is Patrick Williams
// Residing at 2759 Townsgate Road,Lake Village,California 
// zip code is 91362
// mobile num is 2053371197
// my cpin id is 98867954568
// The order is 4 bottles of fresh maple syrup ,delivered by wednesday afternoon.
// Mark the package fragile and make sure to call before delivery.