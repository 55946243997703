import http from "../httpService";

const apiEndpointDE = "/userScoreDataEntry";

function assessmentScoreUrl(userId) {
    return `${apiEndpointDE}/${userId}`;
  }

  export function getDataEntryScore(userId) {
    return http.get(assessmentScoreUrl(userId));
  }

  export function submitDataEntryScore(userId,assessmentName,score_1,score_2) {
    
    return http.post(apiEndpointDE, {
      userId:userId,
      assessmentName:assessmentName,
      score1:score_1.toString(),
      score2:score_2.toString()
    });
  }