import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import Box from '@material-ui/core/Box';
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from 'react-i18next';


class SpeechAssessmentMain extends Component {
  state = {};

  componentDidMount() {
    const { i18n } = this.props;
    const language = localStorage.getItem("selectedLanguage");
    i18n.changeLanguage(language);
  }

  render() {
    const { t } = this.props;
    const card = (
      <React.Fragment>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {t("speechAssessment.Speech Assessment Type")}
          </Typography>
          <Typography variant="h5" component="div">
            {t("speechAssessment.Pronunciation and Verbal Analysis")}
          </Typography>
        </CardContent>
        <CardActions>
          <Button>
            <Link to={`/speechVoiceAssessment/seen`}>
              {t("speechAssessment.Start Assessment")}
            </Link>
          </Button>
        </CardActions>
      </React.Fragment>
    );

    return (
      <div>
        <Box
          sx={{
            minWidth: 275,
            borderRadius: "2",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.1s ease-in-out",
            "&:hover": {
              transform: "scale(1.01)",
            },
          }}
        >
          <Card variant="outlined">{card}</Card>
        </Box>
      </div>
    );
  }
}

export default withTranslation()(SpeechAssessmentMain);
