import http from "./httpService";

const apiEndpoint = "/exportAnalysis";
const apiMcqAssessmentEndpoint="/exportAnalysis/allMcqAssessmentScoreExport"
const apiListeningAssessmentEndpoint="/exportAnalysis/allListeningAssessmentScoreExport"
const apiTypingAssessmentEndpoint="/exportAnalysis/allTypingAssessmentScoreExport"
const apiArticleAssessmentEndpoint="/exportAnalysis/allArticleAssessmentScoreExport"
const apiSpeechAssessmentEndpoint="/exportAnalysis/allSpeechAssessmentScoreExport"


export function getConsolidatedReport(stDate,enDate,uLoc) {
    return http.get(apiEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }
  export function getMcqAssessmentReport(stDate,enDate, uLoc) {
    return http.get(apiMcqAssessmentEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }
  export function getListeningAssessmentReport(stDate,enDate, uLoc) {
    return http.get(apiListeningAssessmentEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }
  export function getTypeAssessmentReport(stDate,enDate, uLoc) {
    return http.get(apiTypingAssessmentEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }
  export function getArticleAssessmentReport(stDate,enDate,uLoc) {
    return http.get(apiArticleAssessmentEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }
  export function getSpeechAssessmentReport(stDate,enDate,uLoc) {
    return http.get(apiSpeechAssessmentEndpoint,{params:{
        userLocation: uLoc,
        startDate: stDate,
        endDate: enDate
    }});
  }

