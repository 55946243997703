import http from "../httpService";

const apiEndpointTS = "/userScoreTypeSpeed";

function assessmentScoreUrl(userId) {
    return `${apiEndpointTS}/${userId}`;
  }

  export function getTypeSpeedScore(userId) {
    return http.get(assessmentScoreUrl(userId));
  }

  export function submitTypeSpeedScore(userId,score_cpm,score_wpm, acc, error) {
    // console.log(userId,score_cpm,score_wpm, acc, error)
    
    return http.post(apiEndpointTS, {
      userId: userId,
      score1wpm: score_wpm.toString(),
      score2cpm: score_cpm.toString(),
      accuracy: acc.toString(),
      errorIndex: error.toString(),
    });
  }