import http from "../httpService";
import _ from "lodash";

// const apiEndpointEW = "/userScoreEssayWriting";
// const apiEndpointEmW = "/userScoreEmailWriting";

const apiEndpointSAV = "/userScoreSpeechAnalysis";
const apiEndpointSAVB = "/userScoreSpeechAnalysisBleu";


export function submitSpeechScoreNew(blob,blobURL,uId,textScript){
  let audioData = new FormData();
  audioData.append("soundBlob", blob, blobURL);
  audioData.append("userId", uId);
  audioData.append("textScriptt",textScript);
  const config = {
    // timeout:1000*30,
    headers: { 'content-type': 'multipart/form-data' }
  }
  return http.post(apiEndpointSAV, audioData,config);
}


function assessmentScoreUrl(userId) {
    return `${apiEndpointSAV}/${userId}`;
  }

function assessmentScoreUrlBleu(userId) {
    return `${apiEndpointSAVB}/${userId}`;
  }

  export function getSpeechAnalysisScore(userId) {
    return http.get(assessmentScoreUrl(userId));
  }

  export function getSpeechAnalysisScoreBleu(userId) {
    return http.get(assessmentScoreUrlBleu(userId));
  }

  export function submitSpeechAnalysisScore(userId,blob_,blobURL_) {
    
    return http.post(apiEndpointSAV, {
      userId:userId,
      soundBlob:blobURL_
    });
  }

  export function submitSpeechAnalysisScoreBleu(userId,blob_,blobURL_) {
    
    return http.post(apiEndpointSAVB, {
      userId:userId,
      soundBlob:blobURL_
    });
  }