import React from 'react';
import Joi from 'joi-browser'
import Form from '../common/form';
import { getModules , saveModule } from '../../services/moduleService';


import { getAssessment } from '../../services/assessmentService';
import "../../designStyles/AssessmentEntryForm.css"
import "../../designStyles/AllForms.css"

class ModuleForm extends Form {
    state = { 
        data:{
            title:"",
           
                },
        modules:[],
        errors:{}
     }

     schema = {
         _id:Joi.string(),
         title:Joi.string().required().label("Title"),         
     }

     async populateModules(){
         const {data : modules} = await getModules();
         this.setState({modules});
     }
     async populateAssessments(){
         try{
             const assessmentId = this.props.match.params.id;
             if(assessmentId === "new") return;

             const {data : assessment} = await getAssessment(assessmentId);
             this.setState({data : this.mapToViewModel(assessment)}); 
         }
         catch(ex){
             if(ex.response && ex.response.status===404)
             this.props.history.replace('/not found')
         }
     }

     async componentDidMount(){

        await this.populateModules();
        
     }
     mapToViewModel(assessment){
         return{
             _id:assessment._id,
             title:assessment.title,
         };
     }

     doSubmit = async ()=>{
         try {
            const response = await saveModule(this.state.data);
            if(response.status===200){
                // window.location="/allAssessments";
                this.props.history.push("/allAssessments");
              }
         } catch (ex) {
            if(ex.response && ex.response.status===400){
                const errors = {...this.state.errors};
                //errors.email = ex.response.data;
                this.setState({errors});
            }
         }
         
     }
    render() { 
        return ( 
            <div className="col-md-8">
                <h1 className="create-title">Create New <span>Modules</span></h1>
                <div className="mdk-divider"></div>
                <div className="form-white">
                    <form onSubmit={this.handleSubmit}>
                        {this.renderInput("title","Title")}
                        {this.renderButton("Save")}

                    </form>
                </div>
            </div>

         );
    }
}
 
export default ModuleForm;
