import React from "react";
import Joi from "joi-browser";
import Form from "./common/form";
import loginWithJwt from "./../services/authService";
import { registerUser } from "./../services/userService";
import "./../designStyles/LoginForm.css";
import { location_options , location_options_In_French} from "../bank/PredifinedUserLocations";
import { withTranslation } from 'react-i18next';
import Select from "react-select";
import { showSuccessToast } from "./utils/toastMessage";

class RegisterForm extends Form {
  constructor(props) {
    super(props);
    this.usernameRef = React.createRef();
  }
  state = {
    data: { username: "", email: "", password: "", userLocation: "" },
    errors: {},
    allLocations: [],
    allLocationsInFrench: [],
    uLocation: null,
  };

  schema = {
    username: Joi.string().min(5).max(50).required().label("Username"),
    email: Joi.string().min(5).max(50).email().required().label("Email"),
    password: Joi.string()
      .trim()
      .min(8)
      .regex(/^(?=.*[A-Z])(?=.*\d).{8,}$/)
      .required()
      .label("Password"),
    userLocation: Joi.string().required().label("User location"),
  };

  setLocation = async () => {
    // const {data:allLocations} = await location_options();
    this.setState({ allLocations: location_options });
    this.setState({ allLocationsInFrench: location_options_In_French });
  };

  handleLocationOption = async (uLocation) => {
    await this.setState({ uLocation });
    const uSelectLocation = this.state.uLocation.value;
    await this.setState({
      data: { ...this.state.data, userLocation: uSelectLocation },
    });
  };

  doSubmit = async () => {
    try {
      // Call the server
      const response = await registerUser(this.state.data);
      if (response.status === 200) {
        showSuccessToast("User Created Successfully");
        this.props.history.push("/login");
      }
      await loginWithJwt(response.headers["x-auth-token"]);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.email = ex?.response?.data?.message;
        this.setState({ errors });
      }
    }
  };

  focusUsernameField() {
    if (this.usernameRef.current) {
      this.usernameRef.current.focus();
    }
  }

  async componentDidMount() {
    await this.setLocation();
    this.focusUsernameField();
    const selectedLanguage = localStorage.getItem("selectedLanguage");
    this.changeLanguage(selectedLanguage);
  }

  changeLanguage(lng) {
    const { i18n } = this.props;
    i18n.changeLanguage(lng);
  }

  render() {
    const { t } = this.props;
    const { uLocation } = this.state;
    const { i18n } = this.props;
    return (
      <React.Fragment>
        <section id="login-page" className="login-page">
          <div className="container-fluid">
            <div className="row align-items-center--">
              <div className="col-sm-6">
                <div className="login-left-main">
                  <img
                    src="images/login-bg-2.jpg"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="login-overlay">
                    <div className="row">
                      <div className="col-sm-12">
                        <h2 className="heading-white">
                          {t("Register.Looks Like You Are A New User")}
                        </h2>
                      </div>
                      <div className="col-sm-12">
                        <p>
                          {t(
                            "Register.Show your true potential with Semantify. The easy to use and intelligent talent assessment, evaluation and management platform, offering an engaging and seamless test experience. Register and start your tests now."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="right-login">
                  <div className="body-widget">
                    <h2 className="heading-black">{t("Register.Register")}</h2>
                    <form onSubmit={this.handleSubmit}>
                      {this.renderInput("username", t("Register.Username*"))}
                      {this.renderInput("email", t("Register.Email*"))}
                      {this.renderPassword(
                        "password",
                        t("Register.Password*"),
                        "password"
                      )}
                      <label>{t("Register.Select Location*")}</label>
                      <Select
                        value={uLocation}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={this.handleLocationOption}
                        options={i18n.language==="en" ? this.state.allLocations : this.state.allLocationsInFrench}
                        maxMenuHeight={150}
                        menuPlacement="auto"
                        isSearchable={true}
                      />
                      <br />
                      {this.renderButton(t("Register.Register"))}
                    </form>
                    <p>
                      {t("Register.Already have an account?")}
                      <a href="/login"> {t("Register.Log In")} </a>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="logo-main">
              <img src="images/logo.png" alt="" />
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default withTranslation()(RegisterForm);
