import React, { useEffect, useState } from "react";
import "../designStyles/NavBar.css";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Select from "react-select";
import { Language } from "../bank/PredifinedUserLocations";

const NavBar = ({ user }) => {
  const { t, i18n } = useTranslation();
 const [selectedLanguage, setSelectedLanguage] = useState("");

 useEffect(() => {
  let languageData = {
    id: localStorage.getItem("selectedLanguage") === "en" ? 1 : 2,
    value: localStorage.getItem("selectedLanguage") || "en",
    label: localStorage.getItem("selectedLanguage") === "fr" ? "Français" : "English"
}
   setSelectedLanguage(languageData);
   localStorage.setItem('selectedLanguage', localStorage.getItem("selectedLanguage") === "fr" ? "fr" : "en");
 }, []);

const handleLanguageChange = async (lng) => {
  i18n.changeLanguage(lng?.value);
  setSelectedLanguage(lng);
  localStorage.setItem('selectedLanguage', lng?.value);
};
  return (
    <React.Fragment>
      <nav id="sidebar">
        <div className="sidebar-header">
          <img src="../images/logo.png" alt="" />
        </div>
        <ul className="list-unstyled components">
          <li>
            {user && user.isAdmin === true && (
              <NavLink className="mdk-nav" to="/mainDashboard">
                <i className="fa fa-home" aria-hidden="true"></i>
                {t("Side Navbar.Dashboard")}
              </NavLink>
            )}
          </li>
          <li>
            <NavLink className="mdk-nav" to="/allAssessmentTypes">
              <i className="fa fa-book" aria-hidden="true"></i>
              {t("Side Navbar.Assessment Types")}
            </NavLink>
          </li>

          <li>
            {user && user.isAdmin === true && (
              <NavLink className="mdk-nav" to="/advancedAnalytics" style={{lineHeight: "95%"}}>
                <i className="fa fa-bar-chart" aria-hidden="true"></i>
                {t("Side Navbar.Custom Analytics")}
              </NavLink>
            )}
          </li>
          <li>
            {user && (
              <NavLink className="mdk-nav" to="/logout">
                <i className="fa fa-sign-out" aria-hidden="true"></i>
                {t("Side Navbar.Logout")}
              </NavLink>
            )}
          </li>
        </ul>
        <div className="nav_lang_translater">
        <Select
        className="my-select"
        classNamePrefix = "my-select"
              value={selectedLanguage}
              onChange={handleLanguageChange}
              options={Language}
              maxMenuHeight={150}
              menuPlacement="auto"
              isSearchable={true}
              style={{ textAlign: "left", background:"red" }}
            />
        </div>
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
