import http from "./httpService";
import _ from "lodash";


const apiEndpoint = "/user";

function userUrl(userId) {
  return `${apiEndpoint}/${userId}`;
}

export function getUsers() {
  return http.get(apiEndpoint);
}
export function getUsersCount() {
  return http.get(apiEndpoint+"/usercount");
}

export function getUsersList(page,limit,search,filter) {
  let para = new URLSearchParams();
  para.append("_page",page);
  para.append("_limit",limit);
  if(search){
    para.append("q",search);
  }
  // if(_.find(filter, {field:"q"})){
  //   para.append("q",_.get(_.find(filter, {field:"q"}),"value"));
  // }
  if(_.find(filter, {field:"name"})){
    para.append("name",_.get(_.find(filter, {field:"name"}),"value"));
  }
  if(_.find(filter, {field:"email"})){
    para.append("email",_.get(_.find(filter, {field:"email"}),"value"));
  }
  if(_.find(filter, {field:"userLocation"})){
    para.append("location",_.get(_.find(filter, {field:"userLocation"}),"value"));
  }
  if(_.find(filter, {field:"registrationTime"})){
    para.append("registrationTime",_.get(_.find(filter, {field:"registrationTime"}),"value"));
  }
  if(_.find(filter, {field:"recruitmentStatus"})){
    para.append("recruitmentStatus",_.get(_.find(filter, {field:"recruitmentStatus"}),"value"));
  }
  // para.append("_page",page)
  // para.append("_page",page)
 let request = {
  params:para
 };
  return http.get(apiEndpoint+"/listUser",request);
}

export function getUsersArchived() {
  return http.get(apiEndpoint+"/archivedUser");
}

export function getOneUser(userId){
  return http.get(userUrl(userId));
}

export function deleteUser(userId){
    return http.delete(apiEndpoint + "/" + userId);
}

export function updateUserProfile(user){
  const finalEndpoint = apiEndpoint +"/userProfileCustomUpdate/"
  if (user._id) {
    const body = { ...user };
    delete body._id;
    // return http.put(userUrl(user._id), body);
    return http.put(finalEndpoint + user._id,body)
  }
}

export function registerUser(user){
  return http.post(apiEndpoint , {
    name:user.username,
    email:user.email,
    password:user.password,
    userLocation:user.userLocation,
  })
}