import React, { Component } from "react";
import Select from "react-select";

import _ from "lodash";
import Switch from "react-switch";

import { getModules } from "../services/moduleService";
import { getAssessments } from "../services/assessmentService";
import { getOneUser, updateUserProfile } from "../services/userService";
import { getCurrentUser } from "../services/authService";
import { addUserAssignmentProfile } from "../services/userAssignmentProfileService";
import { withTranslation } from 'react-i18next';
import "./../designStyles/AllForms.css";
import { showErrorToast, showSuccessToast } from "./utils/toastMessage";

class UserProfileCustom extends Component {
  state = {
    assessments: [],
    modules: [],
    userInfo: {},
    recruiterInfo: {},
    selectedOption: null,
    selectedOption_v2: null,
    recruitmentStatus: "",
    options: [{ value: "", label: "", id: "" }],
    assignedAssessments: [],
    checkedMQ: false,
    checkedLS: false,
    checkedTS: false,
    checkedAW: false,
    checkedSA: false,
    checkArchive: false,
    checkAdmin: false,
  };

  handleChangeMCQ = (checkedMQ) => {
    this.setState({ checkedMQ });
  };
  handleChangeLS = (checkedLS) => {
    this.setState({ checkedLS });
  };
  handleChangeTS = (checkedTS) => {
    this.setState({ checkedTS });
  };
  handleChangeAW = (checkedAW) => {
    this.setState({ checkedAW });
  };
  handleChangeSA = (checkedSA) => {
    this.setState({ checkedSA });
  };
  handleArchive = (checkArchive) => {
    this.setState({ checkArchive });
  };
  handleAdmin = (checkAdmin) => {
    this.setState({ checkAdmin });
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
    this.setState({ assignedAssessments: selectedOption });
  };
  updateOptions = () => {
    this.setState({
      checkedMQ: this.state.userInfo.assignedMQAssessments,
      checkedLS: this.state.userInfo.assignedDEAssessments,
      checkedTS: this.state.userInfo.assignedTSAssessments,
      checkedAW: this.state.userInfo.assignedEWAssessments,
      checkedSA: this.state.userInfo.assignedSAAssessments,
      checkArchive: this.state.userInfo.isArchived,
      checkAdmin: this.state.userInfo.isAdmin,
    });
    this.state.assessments.map((test, i) =>
      this.setState({
        options: [
          ...this.state.options,
          { value: test.title, label: test.title, assessmentId: test._id },
        ],
      })
    );
  };

  handleStatusChange = async (selectedOption_v2) => {
    this.setState({ selectedOption_v2 });
    await this.setState({ recruitmentStatus: selectedOption_v2.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { i18n, t } = this.props;
    try {
      const assignedTests = this.state.assignedAssessments;
      const reqStatus = this.state.recruitmentStatus;
      const { checkedLS, checkedAW, checkedMQ, checkedSA, checkedTS } =
        this.state;
      const { checkArchive, checkAdmin } = this.state;

      await this.setState({
        userInfo: {
          ...this.state.userInfo,
          assignedAssessments: assignedTests,
          recruitmentStatus: reqStatus,
          assignedMQAssessments: checkedMQ,
          assignedDEAssessments: checkedLS,
          assignedTSAssessments: checkedTS,
          assignedEWAssessments: checkedAW,
          assignedSAAssessments: checkedSA,
          isArchived: checkArchive,
          isAdmin: checkAdmin,
        },
      });
      let updateUserInfo = _.pick(this.state.userInfo, [
        "_id",
        "name",
        "email",
        "registrationTime",
        "assignedAssessments",
        "recruitmentStatus",
        "assignedMQAssessments",
        "assignedDEAssessments",
        "assignedTSAssessments",
        "assignedEWAssessments",
        "assignedSAAssessments",
        "isArchived",
        "isAdmin",
      ]);
      updateUserInfo = {...updateUserInfo, language: i18n.language}
      const createProfile = _.pick(this.state.userInfo, [
        "assignedAssessments",
        "recruitmentStatus",
        "assignedMQAssessments",
        "assignedDEAssessments",
        "assignedTSAssessments",
        "assignedEWAssessments",
        "assignedSAAssessments",
        "isArchived",
        "isAdmin",
      ]);
      //   console.log(updateUserInfo)
      //  await updateUserProfile(this.state.userInfo);
      let response = await updateUserProfile(updateUserInfo);
      await addUserAssignmentProfile(
        this.state.userInfo._id,
        this.state.recruiterInfo._id,
        createProfile
      );
      showSuccessToast(t(`assignTest.${response.data.message}`));
      setTimeout(() => {
        window.location = "/mainDashboard";
      }, 3000);
    } catch (ex) {
      if (
        ex.response &&
        ex.response.status >= 400 &&
        ex.response.status <= 500
      ) {
        showErrorToast(t(`common.${ex.response.data.message}`));
      }
    }
  };

  async componentDidMount() {
    try {
      const userId = this.props.match.params.id;
      const { data: userData } = await getOneUser(userId);
      this.setState({ userInfo: userData });
      //console.log(this.state.userInfo.assignedAssessments);

      const { data } = await getModules();
      const modules = data;

      const { data: assessments } = await getAssessments();
      this.setState({ assessments, modules });

      await this.updateOptions();

      const recruiter = getCurrentUser();
      this.setState({ recruiterInfo: recruiter });
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        this.props.history.replace("/not found");
    }
    const { i18n } = this.props;
    const language = localStorage.getItem("selectedLanguage")
    i18n.changeLanguage(language);
  }

  render() {
    const { selectedOption, selectedOption_v2 } = this.state;
    const { t } = this.props;

    const options_v2 = [
      { value: "Not Recruited", label: t("assignTest.Not Recruited") },
      { value: "Recruited", label: t("assignTest.Recruited") },
      { value: "On Hold", label: t("assignTest.On Hold") },
    ];

    return (
      <div>
        <h3 className="mdk-proctroring-title">
          {t("assignTest.Update Profile of Candidate")} :{this.state.userInfo.name}
        </h3>
        <form>
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label>{t("assignTest.Choose MCQ Assessments")}</label>
                <Select
                  value={selectedOption}
                  isMulti
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={this.handleChange}
                  options={this.state.options?.map((module) => {
                    return {
                      ...module,
                      label: module.label
                        ? t(`mcqAssessmentModule.${module.label}`)
                        : module.label,
                    };
                  })}
                />
              </div>
            </div>
            <div className="col-sm-12">
              <div className="form-group">
                <label>{t("assignTest.Recruitment Status")}</label>
                <Select
                  value={selectedOption_v2}
                  onChange={this.handleStatusChange}
                  options={options_v2}
                />
              </div>
            </div>
          </div>

          <div className="permissions">
            {/* MCQ Skills Assessments */}
            <label>
              <span>{t("assignTest.MCQ Skills Assessments")}</span>
              <Switch
                onChange={this.handleChangeMCQ}
                checked={this.state.checkedMQ}
              />
            </label>
            {/* Listening Skills Assessments */}
            <label>
              <span>{t("assignTest.Listening Skills Assessments")}</span>
              <Switch
                onChange={this.handleChangeLS}
                checked={this.state.checkedLS}
              />
            </label>
            {/* Typing Speed Assessments */}
            <label>
              <span>{t("assignTest.Typing Speed Assessments")}</span>
              <Switch
                onChange={this.handleChangeTS}
                checked={this.state.checkedTS}
              />
            </label>
            {/* Article Writing Assessments */}
            <label>
              <span>{t("assignTest.Article Writing Assessments")}</span>
              <Switch
                onChange={this.handleChangeAW}
                checked={this.state.checkedAW}
              />
            </label>
            {/* Speaking Skills Assessments */}
            <label>
              <span>{t("assignTest.Speaking Skills Assessments")}</span>
              <Switch
                onChange={this.handleChangeSA}
                checked={this.state.checkedSA}
              />
            </label>
          </div>
          {this.state.recruiterInfo.isSuperAdmin && (
            <div>
              {/* User Archive */}
              <label>
                <span>{t("assignTest.Archive User")}</span>
                <Switch
                  onChange={this.handleArchive}
                  checked={this.state.checkArchive}
                />
              </label>
            </div>
          )}

          {this.state.recruiterInfo.isSuperAdmin && (
            <div>
              {/* User Archive */}
              <label>
                <span>{t("assignTest.Make Admin")}</span>
                <Switch
                  onChange={this.handleAdmin}
                  checked={this.state.checkAdmin}
                />
              </label>
            </div>
          )}

          <button
            className="btn btn-primary mdk--button"
            type="submit"
            onClick={this.handleSubmit}
          >
            {t("assignTest.Save")}
          </button>
        </form>
      </div>
    );
  }
}
export default withTranslation()(UserProfileCustom)
