import jwtDecode from "jwt-decode";
import http from "./httpService";

const apiEndpoint = "/auth";
const apiUpdatePass = "/auth/updatePass";
const apiForgotPass = "/forgetPassword/forgotPassword";
http.setJwt(getJwt());
const apiGetEmail = "/user/userUuid";

export async function login(email, password) {
  const { data: jwt } = await http.post(apiEndpoint, { email, password });
  localStorage.setItem("token", jwt);
}

export async function getLoginDetails(uuid) {
  const { data } = await http.get(`${apiGetEmail}/${uuid}`);
  return data?.[0];
}

export async function updatePassword(emailId, oldPass, newPass, confirmPass) {
  await http.put(apiUpdatePass, {
    email: emailId,
    oldPassword: oldPass,
    newPassword: newPass,
    confirmPassword: confirmPass,
  });
}

export async function forgotPassword(emailId,language) {
  return await http.post(apiForgotPass, {
    email: emailId,
    language:language
  });
}

// axioms login
/*
export async function login(email, password){
    const data = JSON.stringify({
        "email": email,
        "password": password
      });
      
      var config = {
        method: 'post',
        url: URL,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
}
*/

export function loginWithJwt(jwt) {
  localStorage.setItem("token", jwt);
}

export function logout() {
  localStorage.removeItem("token");
  localStorage.removeItem("attemptAndTimeMCQ");
  localStorage.removeItem("attemptAndTimeListeningQ1");
  localStorage.removeItem("attemptAndTimeListeningQ2");
  localStorage.removeItem("attemptAndTimeListeningQ3");
  localStorage.removeItem("attemptAndTimeWriting");
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem("token");
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function getJwt() {
  return localStorage.getItem("token");
}

export default {
  login,
  logout,
  loginWithJwt,
  getCurrentUser,
  getJwt,
};
