import http from "./httpService";

const apiEndpoint = "/MCQAssessments";

function assessmentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getAssessments() {
  return http.get(apiEndpoint);
}

export function getAssessment(id) {
  return http.get(assessmentUrl(id));
}

export function saveAssessment(title_ , moduleId_ , questionSet_)  {
 /*{ if (assessment._id) {
    const body = { ...assessment };
    delete body._id;
    return http.put(assessmentUrl(assessment._id), body);
  }
}*/
  return http.post(apiEndpoint, {
    title:title_,
    moduleId:moduleId_,
    questionSet : questionSet_

  });
}

export function deleteAssessment(assessmentId) {
  return http.delete(assessmentUrl(assessmentId));
}
