import React, { Component } from "react";

import { Link, NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from 'react-i18next';


class DataEntryModuleMain extends Component {
  constructor(props) {
    super(props);
    const { t } = props;
    this.t = t;

  this.card1 = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {t('ListeningAssessment.Listening Assessment Type')}
        </Typography>
        <Typography variant="h5" component="div">
          {t('ListeningAssessment.Assessment Philippine English 1')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button>
          <Link to={`/dataEntryAssessment/q1`}>{t('ListeningAssessment.Start Assessment')}</Link>
        </Button>
      </CardActions>
    </React.Fragment>
  );
  
  this.card2 = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {t('ListeningAssessment.Listening Assessment Type')}
        </Typography>
        <Typography variant="h5" component="div">
          {t('ListeningAssessment.Assessment American English 1')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button>
          <Link to={`/dataEntryAssessment/q2`}>{t('ListeningAssessment.Start Assessment')}</Link>
        </Button>
      </CardActions>
    </React.Fragment>
  );
  
  this.card3 = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         {t('ListeningAssessment.Listening Assessment Type')}
        </Typography>
        <Typography variant="h5" component="div">
          {t('ListeningAssessment.Assessment Indian English 1')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button>
          {" "}
          <Link to={`/dataEntryAssessment/q3`}>{t('ListeningAssessment.Start Assessment')}</Link>
        </Button>
      </CardActions>
    </React.Fragment>
  );

  this.card4 = (
    <React.Fragment>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {this.t('ListeningAssessment.Listening Assessment Type')}
        </Typography>
        <Typography variant="h5" component="div">
          {this.t('ListeningAssessment.Assessment French')}
        </Typography>
      </CardContent>
      <CardActions>
        <Button>
          {" "}
          <Link to={`/dataEntryAssessment/q4`}>{this.t('ListeningAssessment.Start Assessment')}</Link>
        </Button>
      </CardActions>
    </React.Fragment>
  );
}
componentDidMount(){
  const language = localStorage.getItem("selectedLanguage");
  const { i18n } = this.props;
  i18n.changeLanguage(language);
}
  render() {
    const { i18n, t } = this.props;

    return (
      <>
        <h1 style={{ marginBottom: "32px" }}>
          {t("ListeningAssessment.Listening Assessment")}
        </h1>
        {i18n.language === "fr" ? (
          <Box
            sx={{
              minWidth: 275,
              borderRadius: 2,
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.1s ease-in-out", // Added transition property
              "&:hover": {
                transform: "scale(1.01)", // Added hover effect
              },
            }}
          >
            <Card variant="outlined">{this.card4}</Card>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                minWidth: 275,
                borderRadius: "2",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.1s ease-in-out", // Added transition property
                "&:hover": {
                  transform: "scale(1.01)", // Added hover effect
                },
              }}
            >
              <Card variant="outlined">{this.card1}</Card>
            </Box>
            <br />
            <Box
              sx={{
                minWidth: 275,
                borderRadius: 2,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.1s ease-in-out", // Added transition property
                "&:hover": {
                  transform: "scale(1.01)", // Added hover effect
                },
              }}
            >
              <Card variant="outlined">{this.card2}</Card>
            </Box>
            <br />
            <Box
              sx={{
                minWidth: 275,
                borderRadius: 2,
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                transition: "transform 0.1s ease-in-out", // Added transition property
                "&:hover": {
                  transform: "scale(1.01)", // Added hover effect
                },
              }}
            >
              <Card variant="outlined">{this.card3}</Card>
            </Box>
            <br />
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(DataEntryModuleMain)