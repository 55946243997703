import http from "../httpService";
// const apiEndpointEW = "/userScoreEssayWriting";
// const apiEndpointEmW = "/userScoreEmailWriting";

const apiEndpointEW = "/userScoreEssayWriting";

function assessmentScoreUrl(userId) {
    return `${apiEndpointEW}/${userId}`;
  }

  export function getEssayWritingScore(userId) {
    return http.get(assessmentScoreUrl(userId));
  }

  export function submitEssayWritingScore(userId,textAnswer,questionSel,language) {
    
    return http.post(apiEndpointEW, {
      userId:userId,
      textAnswer:textAnswer,
      questionSelected:questionSel,
      language:language
    });
  }